import React, { useState } from 'react';
import MiddleSection from '../MiddleSection';

const DashboardPage: React.FC = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  return <MiddleSection show={show} handleClose={handleClose} />;
};

export default DashboardPage;
