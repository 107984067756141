import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Box, Typography, Button, LinearProgress } from '@mui/material';
import Web3Context from '../../../context/Web3Context';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import { modal } from '../../../web3config';

const TOKEN_PRICE = 5; // INR 5 per token

const PreSalePage: React.FC = () => {
  const { account, isConnected, connectWallet, disconnectWallet, currentNetwork, walletProvider, web3 } = useContext(Web3Context);
  const [amount, setAmount] = useState<number>(0); // Amount in INR
  const [tokens, setTokens] = useState<number>(0); // Calculated token amount
  const [isLoading, setIsLoading] = useState<boolean>(false); // State to handle loading state

  const jwtToken = localStorage.getItem('jwt'); // Get JWT token from localStorage

  // Handle input change
  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value)) {
      setAmount(0);
      setTokens(0);
    } else {
      setAmount(value);
      setTokens(value / TOKEN_PRICE); // Calculate tokens based on the entered amount
    }
  };


  const checkNetwork = async () => {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      const currentNetwork = await window.tronWeb.fullNode.host;
      if (!currentNetwork.includes('api.trongrid.io')) {
        alert('Please switch to the Tron network in your wallet.');
      } else {
        console.log("Connected to Tron Network.");
      }
    } else {
      console.error("Tron Wallet not connected. Please connect via TronLink.");
    }
  };

  const handleConnect1 = async () => {
    if (isConnected) {
      await disconnectWallet();
    } else {
      try {
        modal.open().then(async () => {
          await connectWallet().then(() => {
            checkNetwork();
          });
        });
      } catch (error) {
        console.error('Error connecting wallet:', error);
      }
    }
  };

  // Transfer token logic (Blockchain transfer)
  const transferTokens = async () => {
    if (web3 && account) {
      try {
        // Assuming you have a contract instance
        const recipientAddress = "0xRecipientWalletAddress"; // Replace with the actual recipient address
        const tokenContract = new web3.eth.Contract(
          // ABI goes here
          [],
          '0xYourContractAddress' // Replace with actual contract address
        );
        const amountToSend = web3.utils.toWei(tokens.toString(), 'ether'); // Convert token amount to wei

        const transaction = await tokenContract.methods
          .transfer(recipientAddress, amountToSend)
          .send({ from: account });

        console.log('Transaction successful:', transaction);
        return transaction;
      } catch (error) {
        console.error('Blockchain transfer error:', error);
        return null;
      }
    }
    return null;
  };

  // Handle token purchase logic
  const handlePurchaseToken = async () => {
    if (!isConnected) {
      Toastify({
        text: 'Please connect your wallet first.',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
      }).showToast();
      return;
    }

    if (amount > 0) {
      setIsLoading(true);
      try {
        // First, call the transfer function to handle blockchain token transfer
        const transferSuccess = await transferTokens();

        if (transferSuccess) {
          // If the blockchain transfer was successful, call the API to record the purchase
          const response = await axios.post('http://localhost/nbitapis/operations/tokenPurchase.php', {
            token: jwtToken, // Pass the JWT token
            amount_paid: amount.toString(), // Convert amount to string for API
          });

          if (response.data.message === 'Token purchase successful') {
            // Show success message
            Toastify({
              text: `Purchase successful! You bought ${response.data.token_amount} NBIT for INR ${response.data.amount_paid}`,
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'center',
              backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
              stopOnFocus: true,
            }).showToast();
          } else {
            Toastify({
              text: 'Token purchase failed. Please try again.',
              duration: 3000,
              newWindow: true,
              close: true,
              gravity: 'top',
              position: 'center',
              backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
              stopOnFocus: true,
            }).showToast();
          }
        } else {
          Toastify({
            text: 'Blockchain transfer failed. Please try again.',
            duration: 3000,
            gravity: 'top',
            position: 'center',
            backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
          }).showToast();
        }
      } catch (error) {
        console.error('Purchase token error:', error);
        Toastify({
          text: 'Network error. Please try again later.',
          duration: 3000,
          gravity: 'top',
          position: 'center',
          backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
        }).showToast();
      } finally {
        setIsLoading(false);
      }
    } else {
      Toastify({
        text: 'Please enter a valid amount.',
        duration: 3000,
        gravity: 'top',
        position: 'center',
        backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
      }).showToast();
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f8f9fc',
        padding: 3,
        marginTop: 6,
        marginLeft: -30,
        height: 'calc(100vh - 64px)', // Ensures the section takes up full viewport height minus topbar
        overflowY: 'auto', // Makes the section scrollable
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {/* Title: Purchase Token */}
      <Typography variant="h5" sx={{ marginBottom: 2, fontWeight: 'bold', color: '#1976d2' }}>
        Purchase NBIT Token
      </Typography>

      {/* Progress Bar */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Pre-Sale Progress
        </Typography>
        <LinearProgress
          variant="determinate"
          value={80} // Replace with actual progress percentage
          sx={{ height: '10px', borderRadius: '5px', backgroundColor: '#e0e0e0', width: 800, alignContent: 'center' }}
        />
        <Typography sx={{ marginTop: 1, fontSize: '0.9rem', color: '#7E7E7E' }}>
          80% of tokens sold (16M/20M tokens)
        </Typography>
      </Box>

      {/* Input box for user to enter amount */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="body1" sx={{ marginBottom: 1, color: '#7E7E7E' }}>
          Enter Amount
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <input
            type="number"
            value={amount || ''}
            onChange={handleAmountChange}
            placeholder="Enter amount"
            style={{
              padding: '10px',
              fontSize: '1.2rem',
              border: '2px solid #1976d2',
              borderRadius: '10px',
              outline: 'none',
              width: '250px',
              marginRight: '10px',
              textAlign: 'center',
            }}
          />
        </Box>
        {/* Display the equivalent tokens */}
        <Typography sx={{ marginTop: 1, color: '#7E7E7E', fontSize: '0.9rem' }}>
          You will get: {tokens.toFixed(2)} NBIT
        </Typography>
      </Box>

      {/* Buttons */}
      <Box sx={{ textAlign: 'center' }}>
        {/* Connect Wallet Button */}
        <Button
          variant="contained"
          sx={{
            marginRight: 2,
            backgroundColor: isConnected ? '#43a047' : '#1976d2',
            color: '#fff',
            borderRadius: '30px',
            padding: '10px 20px',
            fontSize: '1rem',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
          }}
          onClick={() => {
            if (isConnected) {
              Toastify({
                text: 'You are already connected. You can now purchase your tokens.',
                duration: 3000,
                gravity: 'top',
                position: 'center',
                backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
              }).showToast();
            } else {
              connectWallet();
            }
          }}
        >
          {isConnected ? `Connected: ${account?.slice(0, 6)}...${account?.slice(-4)}` : 'Connect Wallet'}
        </Button>

        {/* Purchase Token Button */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: isConnected ? '#ff9800' : '#D3D3D3', // Grey color if not connected
            color: '#fff',
            borderRadius: '30px',
            padding: '10px 20px',
            fontSize: '1rem',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
          }}
          onClick={handlePurchaseToken}
          disabled={!isConnected || isLoading} // Disable button if wallet not connected or loading
        >
          {isLoading ? 'Processing...' : 'Purchase Token'}
        </Button>
      </Box>
    </Box>
  );
};

export default PreSalePage;