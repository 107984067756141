import React, { createContext, useState, ReactNode } from 'react';

interface UserDetails {
  id: number;
  email: string;
  wallet_address: string | null;
  token_balance: string;
  created_at: string;
  updated_at: string;
  role: string;
  status: string;
}

interface PreSalesDetails {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  token_price: string;
  status: string;
  Total_avl_tokens: number;
}

interface UserContextType {
  userDetails: UserDetails | null;
  presalesDetails: PreSalesDetails | null;
  setUserDetails: (data: UserDetails) => void;
  setPresalesDetails: (data: PreSalesDetails) => void;
}

export const UserContext = createContext<UserContextType>({
  userDetails: null,
  presalesDetails: null,
  setUserDetails: () => {},
  setPresalesDetails: () => {},
});

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [presalesDetails, setPresalesDetails] = useState<PreSalesDetails | null>(null);

  return (
    <UserContext.Provider value={{ userDetails, presalesDetails, setUserDetails, setPresalesDetails }}>
      {children}
    </UserContext.Provider>
  );
};