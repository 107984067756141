import React from 'react';
import Content from './components/Main/MainPage';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import LoadingScreen from './components/Main/LoadingScreen';
import { Web3Provider } from './context/Web3Context';
import { UserProvider } from './context/UserContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from '../src/components/Dashboard/Dashboard';
import LoginPage from './components/Main/LoginPage/LoginPage';
import RegisterPage from './components/Main/RegisterPage/RegisterPage';
function App() {
  return (
    <div className="App">
      <Router>
        <Web3Provider>
          <UserProvider>
            <LoadingScreen />
            <Routes>
              <Route path="/" element={<LoginPage />} /> 
              {/* <Route path="/" element={<Dashboard />} /> */}
              <Route path="/dashboard" element={<Dashboard />} /> {/* Dashboard route */}
              <Route path='/login' element={<LoginPage />} />
              <Route path='/register' element={<RegisterPage />} />
            </Routes>
          </UserProvider>
        </Web3Provider>
      </Router>
    </div>
  );
}

export default App;