import React, { createContext, useState, useEffect } from 'react';
import Web3 from 'web3';
import { connectWallet, disconnectWallet, getConnectedAddress } from '../web3config';

interface Web3ContextType {
  web3: Web3 | null;
  account: string | null;
  isConnected: boolean;
  currentNetwork: string | null;
  walletProvider: string | null;
  connectWallet: () => Promise<Web3 | null>;
  disconnectWallet: () => void;
}

const Web3Context = createContext<Web3ContextType>({
  web3: null,
  account: null,
  isConnected: false,
  currentNetwork: null,
  walletProvider: null,
  connectWallet: async () => null,
  disconnectWallet: () => {},
});

export const Web3Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [web3, setWeb3] = useState<Web3 | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [currentNetwork, setCurrentNetwork] = useState<string | null>(null);
  const [walletProvider, setWalletProvider] = useState<string | null>(null);

  const handleConnectWallet = async (): Promise<Web3 | null> => {
    try {
      console.log("Attempting to connect wallet...");
      const provider = await connectWallet();
      if (provider) {
        const web3Instance = new Web3(provider);
        const address = await getConnectedAddress(provider);
        
        // Detect wallet provider
        if (window.ethereum && window.ethereum.isMetaMask) {
          setWalletProvider('MetaMask');
          console.log("Network is Metamask");
        } else if (window.ethereum && window.ethereum.isTrust) {
          setWalletProvider('Trust Wallet');
          console.log("Network is Trust");
        } else if (window.tronWeb) {
          setWalletProvider('TronLink');
          console.log("Network is Tron");
        }

        // Get the current network
        const chainId = await web3Instance.eth.getChainId(); // Convert BigInt to number
        let networkName = '';
        console.log(chainId);
        switch (Number(chainId)) {
          case 1:
            networkName = 'eth';
            break;
          case 56:
            networkName = 'bsc';
            break;
          case 11111: // Replace with Tron chain ID if needed
            networkName = 'tron';
            break;
          default:
            networkName = 'unknown';
        }
        setCurrentNetwork(networkName);

        if (address) {
          setWeb3(web3Instance);
          setAccount(address);
          setIsConnected(true);
          localStorage.setItem('connectedAccount', address);
          return web3Instance;
        } else {
          console.error("No accounts found.");
        }
      }
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
    return null;
  };

  const handleDisconnectWallet = async () => {
    try {
      await disconnectWallet();
      setWeb3(null);
      setAccount(null);
      setIsConnected(false);
      setCurrentNetwork(null);
      setWalletProvider(null);
      localStorage.removeItem('connectedAccount');
      console.log("Wallet disconnected.");
    } catch (error) {
      console.error('Failed to disconnect wallet:', error);
    }
  };

  useEffect(() => {
    const checkConnectedWallet = async () => {
      const connectedAccount = localStorage.getItem('connectedAccount');
      if (connectedAccount) {
        console.log("Restoring connected wallet:", connectedAccount);
        const provider = await connectWallet();
        if (provider) {
          const web3Instance = new Web3(provider);
          setWeb3(web3Instance);
          setAccount(connectedAccount);
          setIsConnected(true);

          // Restore network and provider
          const chainId = await Number(web3Instance.eth.getChainId());
          let networkName;
          switch (chainId) {
            case 1:
              networkName = 'eth';
              break;
            case 56:
              networkName = 'bsc';
              break;
            case 11111:
              networkName = 'tron'; // Replace with Tron chain ID if needed
              break;
            default:
              networkName = 'unknown';
          }
          setCurrentNetwork(networkName);

          if (window.ethereum && window.ethereum.isMetaMask) {
            setWalletProvider('MetaMask');
          } else if (window.ethereum && window.ethereum.isTrust) {
            setWalletProvider('Trust Wallet');
          } else if (window.tronWeb) {
            setWalletProvider('TronLink');
          }
        }
      }
    };

    checkConnectedWallet();
  }, []);

  return (
    <Web3Context.Provider
      value={{
        web3,
        account,
        isConnected,
        currentNetwork,
        walletProvider,
        connectWallet: handleConnectWallet,
        disconnectWallet: handleDisconnectWallet,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export default Web3Context;