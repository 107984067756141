import React, { useState, useContext } from 'react';
import axios from 'axios';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import './RegisterPage.css';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import OTPModal from './OtpModal'; // OTP Modal component
import registerImage from '../../../assets/images/girl.png';
import Web3Context from '../../../context/Web3Context';

const RegisterPage: React.FC = () => {
  const { connectWallet, disconnectWallet, account, isConnected } = useContext(Web3Context);
  const { setUserDetails } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [userEmail, setUserEmail] = useState(''); // Store user email for OTP verification
  const navigate = useNavigate();

  // Shorten wallet address
  const shortenAddress = (address: string | null, chars = 4): string => {
    if (!address) return '';
    return `${address.slice(0, chars)}...${address.slice(-chars)}`;
  };


  // Handle email/password registration
  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      showErrorMessage("Passwords do not match");
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post('https://nbitai.gamingpandastudios.com/apisnbit/nbitapis/auth/registerUser.php', {
        email,
        password,
      });

      if (response.data.message === 'OTP sent. Please verify your email to complete registration.') {
        showSuccessMessage(response.data.message);
        setUserEmail(email);
        setShowOTPModal(true); // Open OTP modal after registration
      } else {
        showErrorMessage(response.data.error || "Registration failed");
      }
    } catch (error) {
      showErrorMessage("Registration failed due to network error");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle wallet registration
  const handleConnect = async () => {
    try {
      if (!isConnected) {
        await connectWallet();
      }

      const response = await axios.post('https://nbitai.gamingpandastudios.com/apisnbit/nbitapis/auth/registerUser.php', {
        wallet_address: account,
      });

      if (response.data.message === 'User registered successfully') {
        localStorage.setItem('jwt', response.data.jwt);
        setUserDetails(response.data.user_details); // Set user details in context
        showSuccessMessage("User registered successfully with Wallet!");
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      } else {
        showErrorMessage(response.data.error || "Registration failed");
      }
    } catch (error) {
      showErrorMessage("Failed to connect wallet");
    }
  };

  // Show success message
  const showSuccessMessage = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      gravity: 'top',
      position: 'center',
      backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
    }).showToast();
  };

  // Show error message
  const showErrorMessage = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      gravity: 'top',
      position: 'center',
      backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
    }).showToast();
  };

  return (
    <div className="register-page">
      <div className="register-image-section">
        <h1>Welcome to NbitAI</h1>
        <img src={registerImage} alt="Register Illustration" className="register-image" />
        <p>Join the Fastest Growing AI Community Today</p>
      </div>

      <div className="register-form-section">
        <h2>Create Your Account</h2>
        <form onSubmit={handleRegister}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Please enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Set password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="terms">
            <input type="checkbox" id="terms" required />
            <label htmlFor="terms">
              I agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
            </label>
          </div>
          <button type="submit" className="register-button" disabled={isLoading}>
            {isLoading ? 'Processing...' : 'Register'}
          </button>
        </form>

        <p className="login-link">
          Already have an account? <a href="/login">Log In</a>
        </p>

        <div className="wallet-connect-section">
          <p>Or Connect With</p>
          <button type="button" className="wallet-connect-button" onClick={handleConnect}>
            <img src="https://mlmnew.gamingpandastudios.com/rainmaker/assets/images/icons/wallet.svg" alt="Wallet Connect" />
            {isConnected ? `Connected: ${shortenAddress(account)}` : 'Connect Wallet'}
          </button>
        </div>
      </div>
   {/* Render OTP Modal */}
   {showOTPModal && (
        <OTPModal email={userEmail} setShowOTPModal={setShowOTPModal} />
      )}
    </div>
  );
};

export default RegisterPage;