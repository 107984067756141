import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import DashboardPage from './pages/DashboardPage';
import AboutTokenPage from './pages/AboutTokenPage';
import PurchasePage from './pages/PurchasePage';
import ViewProfilePage from './pages/ViewProfilePage';
import UpdateProfilePage from './pages/UpdateProfilePage';
import { UserContext } from '../../context/UserContext';

const drawerWidth = 240;

const Dashboard: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [activePage, setActivePage] = useState('Dashboard');
  const { setUserDetails, setPresalesDetails } = useContext(UserContext);
  const navigate = useNavigate(); // Initialize useNavigate for redirecting to login

  // Function to toggle the sidebar open/close state
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Fetch user details from the API
  useEffect(() => {
    const token = localStorage.getItem('jwt'); // Get JWT token from localStorage

    // Check if token is available, if not redirect to /login
    if (!token) {
      navigate('/login'); // Redirect user to login page
      return; // Stop further execution if there's no token
    }

    const fetchUserDetails = async () => {
      const response = await fetch('https://nbitai.gamingpandastudios.com/apisnbit/nbitapis/operations/fetchUser.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        setUserDetails(data.user_details); // Store user details
        setPresalesDetails(data.presales_details); // Store presales details
      } else {
        console.error('Failed to fetch user details');
        // navigate('/dashboard'); // If fetch fails, redirect to login
      }
    };

    fetchUserDetails();
  }, [setUserDetails, navigate]);

  // Render the correct page based on the selected sidebar button
  const renderContent = () => {
    switch (activePage) {
      case 'Dashboard':
        return <DashboardPage />;
      case 'Purchase':
        return <PurchasePage />;
      case 'AboutToken':
        return <AboutTokenPage />;
      case 'ViewProfile':
        return <ViewProfilePage onUpdateProfile={() => setActivePage('UpdateProfile')} />;
      case 'UpdateProfile':
        return <UpdateProfilePage />;
      default:
        return <DashboardPage />;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Topbar */}
      <Topbar toggleSidebar={toggleSidebar} />

      {/* Sidebar */}
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        activePage={activePage}
        setActivePage={setActivePage}
      />

      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 3,
          transition: 'margin 0.3s ease-in-out',
          marginLeft: isSidebarOpen ? `${drawerWidth}px` : '0px',
          marginTop: '64px',
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  );
};

export default Dashboard;