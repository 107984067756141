import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
// import { Web3Provider } from './context/Web3Context';
// import { Web3ModalProvider } from './web3config';
import { Buffer } from 'buffer';
import process from 'process';

window.Buffer = Buffer;
window.process = process;

ReactDOM.render(
  <React.StrictMode>
  {/* <Web3ModalProvider>
  <Web3Provider> */}
    <App />
  {/* </Web3Provider>, */}
  {/* </Web3ModalProvider>, */}
  </React.StrictMode>,
  document.getElementById('root')
);