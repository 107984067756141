import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import WhitePaperPDF from '../../../assets/Whitepaper.pdf' // Make sure to replace these paths with your actual files
import TokenomicsPDF from '../../../assets/Tokenomics.pdf';

const AboutTokenPage: React.FC = () => {

  return (
    <Box
    sx={{
      backgroundColor: '#f8f9fc',
      padding: 3,
      marginTop: 6,
      marginLeft: -30,
      height: 'calc(100vh - 64px)', // Ensures the section takes up full viewport height minus topbar
      overflowY: 'auto', // Makes the section scrollable
      display: 'flex',
      flexDirection: 'column',
    }}
  >
      {/* Page Title */}
      <Typography
        variant="h4"
        sx={{
          marginBottom: 4,
          fontWeight: 'bold',
          color: '#1976d2',
          textAlign: 'center',
        }}
      >
        Download our Whitepaper and Tokenomics
      </Typography>

      {/* Container for the two download boxes */}
      <Box
        sx={{
          display: 'flex',
          gap: '20px', // Spacing between the boxes
        }}
      >
        {/* Whitepaper Box */}
        <Box
          sx={{
            width: '200px',
            height: '200px',
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Soft shadow
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition
            '&:hover': {
              transform: 'scale(1.05)', // Slightly scale up on hover
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)', // Stronger shadow on hover
            },
          }}
          onClick={() => window.open(WhitePaperPDF, '_blank')}
        >
          <Typography variant="h6" sx={{ color: '#1976d2', textAlign: 'center' }}>
            Whitepaper
          </Typography>
          {/* Download icon (shown on hover) */}
          <IconButton
            sx={{
              display: 'none',
              position: 'absolute',
              '&:hover': {
                display: 'block', // Show icon only on hover
              },
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Box>

        {/* Tokenomics Box */}
        <Box
          sx={{
            width: '200px',
            height: '200px',
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)', // Soft shadow
            cursor: 'pointer',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition
            '&:hover': {
              transform: 'scale(1.05)', // Slightly scale up on hover
              boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)', // Stronger shadow on hover
            },
          }}
          onClick={() => window.open(TokenomicsPDF, '_blank')}
        >
          <Typography variant="h6" sx={{ color: '#1976d2', textAlign: 'center' }}>
            Tokenomics
          </Typography>
          {/* Download icon (shown on hover) */}
          <IconButton
            sx={{
              display: 'none',
              position: 'absolute',
              '&:hover': {
                display: 'block', // Show icon only on hover
              },
            }}
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutTokenPage;
