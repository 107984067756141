import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Toastify from 'toastify-js';
import { useNavigate } from 'react-router-dom';
import './OtpModal.css'; // CSS for styling the modal

interface OTPModalProps {
  email: string;
  setShowOTPModal: (value: boolean) => void;
}

const OTPModal: React.FC<OTPModalProps> = ({ email, setShowOTPModal }) => {
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(300); // 5 minutes in seconds
  const navigate = useNavigate();

  // Countdown timer logic
  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleVerifyOTP = async () => {
    if (!otp) {
      showErrorMessage("Please enter the OTP.");
      return;
    }
    setIsLoading(true);
    console.log(email);
    try {
      const response = await axios.post('https://nbitai.com/apisnbit/nbitapis/auth/verifyOtp.php', {
        email,
        otp_code: otp,
      });

      if (response.data.message === 'OTP verified successfully. Registration complete.') {
        localStorage.setItem('jwt', response.data.jwt);
        showSuccessMessage(response.data.message);
        setShowOTPModal(false);
        navigate('/dashboard');
      } else {
        showErrorMessage(response.data.error || "OTP verification failed.");
      }
    } catch (error) {
      showErrorMessage("OTP verification failed due to network error.");
    } finally {
      setIsLoading(false);
    }
  };

  // Show success message
  const showSuccessMessage = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      gravity: 'top',
      position: 'center',
      backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
    }).showToast();
  };

  // Show error message
  const showErrorMessage = (message: string) => {
    Toastify({
      text: message,
      duration: 3000,
      gravity: 'top',
      position: 'center',
      backgroundColor: 'linear-gradient(to right, #FF0000, #CB4335)',
    }).showToast();
  };

  return (
    <div className="otp-modal-overlay">
      <div className="otp-modal-content">
        <h2>Verify OTP</h2>
        <p>A one-time password has been sent to your email.</p>
        <p>Enter the OTP below to complete your registration.</p>
        <div className="input-group">
          <label htmlFor="otp">Enter OTP</label>
          <input
            type="text"
            id="otp"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
        </div>
        <p className="countdown">Time remaining: {formatTime(countdown)}</p>
        <button onClick={handleVerifyOTP} className="verify-button" disabled={isLoading}>
          {isLoading ? 'Verifying...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default OTPModal;