// src/web3Config.ts
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { mainnet, bsc } from 'viem/chains'; 
import Web3 from 'web3';

const projectId = '41f9dc33709317f9057e09300fd154dc';

const metadata = {
  name: 'NbitAI Web',
  description: 'Invest in future',
  url: 'https://nbitai.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const chains = [mainnet, bsc] as const; 

export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

export const modal = createWeb3Modal({
  wagmiConfig: config,
  projectId,
});

export const connectWallet = async () => {
  const { connectors } = config;
  const connector = connectors[0]; // Assuming first connector is used
  await connector.connect();
  const provider = (await connector.getProvider()) as any; // Type assertion for provider
  return provider;
};

export const getConnectedAddress = async (provider: any) => {
  const accounts = await provider.request({ method: 'eth_accounts' });
  console.log(accounts);
  return accounts[0]; // Returning the first account
};

export const disconnectWallet = async () => {
  const { connectors } = config;
  const connector = connectors[0]; // Assuming first connector is used
  const provider = (await connector.getProvider()) as any; // Type assertion for provider
  if (provider.disconnect) {
    await provider.disconnect();
  }
};